<template>
    <div>
      <page-title :heading=heading :subheading=subheading></page-title>
      
      <!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Order Details</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button id="txtBtn" type="text" size="default" class="txtBtn mr-3 pt-3" style="color: #FB8C00; font-size: 16px;" @click="onEditItem">
						<a-icon type="form" /> Edit
					</a-button>

					<a-button type="primary" :href="`/shop-management/orders`" class="">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-0" />Back
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>

			
			<div class="pb-0 mb-0 mt-3">
			
				<a-row :gutter="24" class="">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Customer Name</strong></label>
						<a-input 
							disabled
							v-model="saleDetails.customer"
							/>
					</a-col>

					<a-col :span="24" :md="12">
						<label><strong>Payment Method</strong></label>
						<a-input 
							disabled
							v-model="saleDetails.method"
							/>
					</a-col>
				</a-row>

				<a-row :gutter="24" class="">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Location</strong></label>
						<a-input 
							disabled
							v-model="saleDetails.location"
							/>
					</a-col>

					<a-col :span="24" :md="12">
						<label><strong>Description</strong></label>
						<a-input 
							disabled
							v-model="saleDetails.statusName"
							/>
					</a-col>
				</a-row>

				<a-row :gutter="24" class="pb-4">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Description</strong></label>
						<a-textarea 
							disabled
							rows="3"
							v-model="saleDetails.description"
							/>
					</a-col>
				</a-row>
			
				<div class="mt-5">
					<a-row type="flex" :gutter="24" class="pb-4">
						<a-col :span="24" :md="24">
							<a-table class="mt-20 pb-2"  
								size="middle"
								:responsive="true"
								:bordered="false"
								:columns="columns" 
								:data-source="saleDetails.items" 
								:rowKey="record => record.uuid"
								:pagination="false">

									<template slot="s_no" slot-scope="s_no, record, index">
										{{ index + 1 }}
									</template>

									<template slot="price" slot-scope="price, record">
										{{ record.product.price.price }}
									</template>

									<template slot="createdAt" slot-scope="createdAt">
										{{ $Moment(createdAt).format("DD MMM YYYY - hh:mm:ss A ") }}
									</template>
								
							</a-table>
						</a-col>
					</a-row>
				</div>


				<a-row :gutter="24" class="mt-3 pb-5">
					<a-col :span="24" :md="13">
						
					</a-col>

					<a-col :span="24" :md="7" class="pb-5">
						<label>Total Amount: <strong>{{ saleDetails.totalAmount }}/= Tshs</strong></label>
						
					</a-col>

					
				</a-row>
					
			</div>
		</a-card>


		<!-- FORM MODAL -->
		<a-modal v-model="saleDetails.showModal" :footer="null">
			<h4 class="text-center">{{ saleDetails.isCreate ? 'Add Item' : 'Edit Item'}}</h4>
			
			<a-form-model
				:model="saleDetails"
                :form="form"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleEditItem">

					<a-form-item 
						class="my-0 mt-4 pb-0" 
						label="Status"
						placeholder="Select Status"
						:colon="false">
						<a-select placeholder="Select Status" 
							name="status"
							v-model="saleDetails.status"
							:options="statuses.map(option => ({ label: option.name, value: option.uuid }))" />
					</a-form-item>

                    <a-form-item class="mt-4"> 
						<a-button type="primary" color="#D15A1B" :loading="saleDetails.loading" html-type="submit" class="py-1">
							{{ saleDetails.isCreate ? 'Add' : 'Update' }}
						</a-button>
					</a-form-item>

                </a-form-model>
			
		</a-modal>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
			// width: "5%"
        },
		{
			title: 'PRODUCTS',
			dataIndex: 'product.name',
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'SELLING PRICE(@)',
			dataIndex: 'price',
			scopedSlots: { customRender: 'price' },
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'QUANTITY',
			dataIndex: 'quantity',
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'DISCOUNT',
			dataIndex: 'discount',
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'SUBTOTAL',
			dataIndex: 'amount',
			// width: "8%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
			// width: "10%"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
            heading: 'Orders',
            subheading: 'Order Details',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
				{ age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
				{ age: 21, first_name: 'Larsen', last_name: 'Shaw' },
				{ age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            pagination: {
                search: '',
                loading: false,
                perPage: 10,
                currentPage: 1,
                perPageOptions: [
                    {label: "10", value: 10}, 
                    {label: "20", value: 20}, 
                    {label: "30", value: 30}, 
                    {label: "50", value: 50}
                ],
                total: 10
            },

            columns,
            message: '',

            medicines: [],
            
            form: '',
            rules,
            saleDetails: {
                uuid: null,
                companyUuid: null,
                customer: null,
                method: null,
				location: null,
				status: null,
				statusName: null,
				description: null,
				items: [],
				totalAmount: 0.0,
                isCreate: true,
                loading: false,
                showModal: false,
            },


			statuses: [
				{ uuid: "pending", name: "Pending" },
				{ uuid: "delivered", name: "Delivered" },
				{ uuid: "cancelled", name: "Cancelled" },
			],


            deleteDataItem: {
                uuid: null,
                showModal: false,
            },

            userDetails: {
                user: null,
                company: null
            },

            notify: {
                text: null,
                type: null,
            },
        }
		},
		created() {
            this.getUserDetails();
			this.getSaleDetails();
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },


			getTotalAmount(items) {
				let totalAmount = 0.0

				items.map(item => {
					totalAmount += parseFloat(item.amount)
				});

				this.saleDetails.totalAmount = totalAmount.toFixed(2).toLocaleString("en-US");
			},

			async getSaleDetails() {

                let url = `${this.$BACKEND_URL}/sales/show/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.saleDetails.uuid = response.data.uuid;
						this.saleDetails.customer = response.data.customer;
						this.saleDetails.location = response.data.location;
						this.saleDetails.statusName = this.formatName(response.data.status);
						this.saleDetails.status = response.data.status;
						this.saleDetails.description = response.data.description;
						this.saleDetails.method = `${response.data.method[0].toUpperCase()}${response.data.method.substring(1)}`;
						this.saleDetails.items = response.data.items;

						this.getTotalAmount(response.data.items)
                    }
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)
                });
            },


			onEditItem() {
				this.saleDetails.loading = false;
				this.saleDetails.isCreate = false;
				this.saleDetails.showModal = true;
			},


			async handleEditItem() {

				this.saleDetails.loading = true;
	
				let url = `${this.$BACKEND_URL}/sales/${this.$route.params.uuid}`

				this.$AXIOS.patch(url, { status: this.saleDetails.status }).then(response => {

					if (response.status >= 200 && response.status < 210) {

						this.saleDetails.isCreate = true;
						this.saleDetails.showModal = false;

						this.notify.text = response.data.message;
						this.notify.type = "success"

						this.$notify(this.notify)

					}


					this.getSaleDetails()

					this.saleDetails.loading = false;

				}).catch(error => {
				
					this.saleDetails.loading = false;
		
					if(error.response != null && error.response.status == 401) {
						this.$router.push(`/`);
					}

					this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
					this.notify.type = "error"

					this.$notify(this.notify)
		
				});

			},



		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>